<template>
    <div>
        <company-culture-pc v-if="$responsive.is('pc')"></company-culture-pc>
        <company-culture-mobile v-if="$responsive.is('mobile')"></company-culture-mobile>
    </div>
</template>


<script>
    import CompanyCulturePC from './company-culture-pc';
    import CompanyCultureMobile from './company-culture-mobile';

    export default {
      components: {
        'company-culture-pc': CompanyCulturePC,
        'company-culture-mobile': CompanyCultureMobile,
      },
    }
</script>
